import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  Chart,
  ChartOptions,
  ColorAxisOptions,
  LegendOptions,
  PlotOptions,
  PointOptionsObject,
  SeriesHeatmapOptions,
  TitleOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
  Options,
} from 'highcharts';
import { ChartComponent } from '../chart/chart.component';
import { BehaviorSubject } from 'rxjs';
import { GridData } from '../graph-util.service';
import { ExportableChart } from '../exportable-chart';
import { heatmapTooltipFormatter } from '../tooltips/heatmap-tooltip';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-graph-heatmap',
  templateUrl: './graph-heatmap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent, AsyncPipe],
})
export class GraphHeatmapComponent implements OnInit, OnChanges, ExportableChart {
  @HostBinding('class') readonly hostClass = 'd-block overflow-auto w-100';
  @Input() title: string;
  @Input() xAxisTitle: string;
  @Input() yAxisTitle: string;
  @Input() data: HeatmapData;
  @Input() isWrapped = false;
  @Input() set animations(value: boolean) {
    this.chartOptions = {
      ...this.chartOptions,
      animation: value,
    };
  }

  @Output() graphLoaded = new EventEmitter<any>();

  @ViewChildren('chart') charts: QueryList<ChartComponent>;

  chartOptions: ChartOptions = {
    type: 'heatmap',
  };
  titleOptions: TitleOptions = {};
  xAxisOptions: XAxisOptions = {};
  yAxisOptions: YAxisOptions = {};
  plotOptions: PlotOptions = {
    series: {
      turboThreshold: 0,
    },
  };

  seriesOptions: SeriesHeatmapOptions[];

  heatmaps$: BehaviorSubject<HeatmapChartOptions[]> = new BehaviorSubject([]);
  tooltipOptions: TooltipOptions = {};
  legendOptions: LegendOptions = {
    enabled: true,
  };
  colorAxis: ColorAxisOptions = {
    min: 0,
    type: 'linear',
    stops: [
      [0, '#ffffff'],
      [0.5, '#3E9583'],
      [1, '#1F2D86'],
    ],
  };

  private readonly CELL_SIZE = 20;
  // All of these numbers below are somewhat arbitrary and are just values that work for all cases so far.
  private readonly DEFAULT_MARGINS = {
    top: 50,
    right: 20,
    bottom: 160,
    left: 110,
  };
  private readonly MINIMUM_GRAPH_WIDTH = 410;
  private readonly SMALL_NUMBER_OF_CELLS = 5;
  private readonly LARGE_LABEL_LENGTH = 12;
  private readonly LARGE_AXIS_TITLE_LENGTH = 10;
  private readonly LARGE_TITLE_LENGTH = 40;
  private readonly WRAPPED_TITLE_ADJUSTMENT = 40;

  constructor(private elemRef: ElementRef) {}

  downloadImage(documentName?: string) {
    if (this.charts) {
      const name = documentName
        ? `${this.titleOptions.text} (${documentName})`
        : this.titleOptions.text;
      const { svg, width, height } = this.getChartAsSVG();
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d', { alpha: false });

      const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      const image = new Image();
      image.onload = () => {
        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);
        context.drawImage(image, 0, 0, width, height);

        const link = document.createElement('a');
        link.download = name;
        link.href = canvas.toDataURL();
        link.click();
        link.remove();
      };
      image.src = url;
    }
  }

  ngOnInit() {
    this.titleOptions.text = this.title;
    this.xAxisOptions.title = { text: this.xAxisTitle };
    this.yAxisOptions.title = { text: this.yAxisTitle };

    this.setupHeatmaps();
  }

  ngOnChanges({ title, xAxisTitle, yAxisTitle, showDataLabels, data, isWrapped }: SimpleChanges) {
    if (title && !title.firstChange) {
      this.titleOptions.text = title.currentValue;
    }

    if (xAxisTitle && !xAxisTitle.firstChange) {
      this.xAxisOptions.title.text = xAxisTitle.currentValue;
    }

    if (yAxisTitle && !yAxisTitle.firstChange) {
      this.yAxisOptions.title.text = yAxisTitle.currentValue;
    }

    if ((data && !data.firstChange) || (isWrapped && !isWrapped.firstChange)) {
      this.setupHeatmaps();
    }
  }

  onChartLoad(chart: Chart) {
    this.graphLoaded.emit(chart.container?.firstElementChild);
  }

  setupHeatmaps() {
    if (this.data) {
      this.tooltipOptions = {
        ...this.tooltipOptions,
        formatter: heatmapTooltipFormatter(this.data.tooltipLabel),
      };
      const numberOfXCells = this.data.labels?.x.length;
      this.yAxisOptions.categories = this.data.labels?.y;
      this.setMargins();
      this.chartOptions.height =
        this.data.labels?.y.length * this.CELL_SIZE +
        this.chartOptions.marginTop +
        this.chartOptions.marginBottom;
      this.colorAxis.max = this.data.series.data.reduce(
        (max, point) => Math.max(max, GraphHeatmapComponent.getPointValue(point)),
        0,
      );

      if (this.isWrapped) {
        const cells = Math.floor(
          (Math.max(this.elemRef?.nativeElement?.offsetWidth, this.MINIMUM_GRAPH_WIDTH) -
            (this.chartOptions.marginLeft + this.chartOptions.marginRight)) /
            this.CELL_SIZE,
        );

        const heatmaps: HeatmapChartOptions[] = [];
        for (let i = 0; i < Math.ceil(this.data.labels.x.length / cells); i++) {
          const seriesData = this.data.series.data
            .filter(
              (point) =>
                GraphHeatmapComponent.getPointX(point) >= i * cells &&
                GraphHeatmapComponent.getPointX(point) < (i + 1) * cells,
            )
            .map((point) => {
              const x = GraphHeatmapComponent.getPointX(point) - i * cells;
              return [
                x,
                GraphHeatmapComponent.getPointY(point),
                GraphHeatmapComponent.getPointValue(point),
              ];
            });

          const endOfRow = Math.min((i + 1) * cells, this.data.labels.x.length);
          const xLabels = this.data.labels?.x.slice(i * cells, endOfRow);
          const numberOfColumns = Math.min(numberOfXCells - cells * i, cells);
          const marginRight = this.getMarginRight(numberOfColumns);

          heatmaps.push({
            series: [
              {
                ...this.data.series,
                data: seriesData,
              },
            ],
            xAxisOptions: {
              ...this.xAxisOptions,
              categories: xLabels,
            },
            chartOptions: {
              ...this.chartOptions,
              width: this.getWidth(numberOfColumns, marginRight),
              marginRight: marginRight,
              marginBottom: this.getMarginBottom(numberOfColumns),
            },
          });
        }
        this.heatmaps$.next(heatmaps);
      } else {
        this.xAxisOptions.categories = this.data.labels?.x;
        this.heatmaps$.next([
          {
            series: [this.data.series],
            xAxisOptions: this.xAxisOptions,
            chartOptions: {
              ...this.chartOptions,
              width: this.getWidth(numberOfXCells, this.chartOptions.marginRight),
            },
          },
        ]);
      }
    }
  }

  @HostListener('window:resize', ['$event.target'])
  resize() {
    if (this.isWrapped) {
      this.setupHeatmaps();
    }
  }

  private getChartAsSVG() {
    const chartSVGs = [];
    let offsetTop = 0;
    let maxWidth = 0;

    const charts = this.charts.toArray();

    for (let i = 0; i < charts.length; i++) {
      const option: Options = {
        title: i === 0 ? this.titleOptions : undefined,
      };
      let chartSVG = charts[i].chart.getSVG(option);
      const chartWidth = +chartSVG.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
      const chartHeight = +chartSVG.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];

      chartSVG = chartSVG
        .replace('<svg', '<g transform="translate(0,' + offsetTop + ')" ')
        .replace('</svg>', '</g>');

      offsetTop += chartHeight;
      maxWidth = Math.max(maxWidth, chartWidth);
      chartSVGs.push(chartSVG);
    }

    return {
      svg: `<svg height="${offsetTop}" width="${maxWidth}" xmlns="http://www.w3.org/2000/svg">${chartSVGs.join(
        '',
      )}</svg>`,
      width: maxWidth,
      height: offsetTop,
    };
  }

  private getWidth(cells: number, marginRight: number): number {
    return cells * this.CELL_SIZE + this.chartOptions.marginLeft + marginRight;
  }

  /**
   * If the graph is too small then it needs larger margins to avoid overlapping of titles etc.
   * All numbers in the getters are just values that seem to work best for most cases.
   */
  private setMargins() {
    this.chartOptions.marginTop = this.getMarginTop();
    this.chartOptions.marginRight = this.getMarginRight(this.data.labels?.x.length);
    this.chartOptions.marginBottom = this.getMarginBottom(this.data.labels?.x.length);
    this.chartOptions.marginLeft = this.getMarginLeft();
  }

  private getMarginTop(): number {
    // Title can potentially wrap multiple lines when not enough horizontal space.
    const adjustment = this.titleIsLong ? this.WRAPPED_TITLE_ADJUSTMENT : 0;
    return this.DEFAULT_MARGINS.top + adjustment;
  }

  private getMarginRight(numberOfXCells: number): number {
    // Gives the title and legend a bit more horizontal room for small heatmaps.
    const plotSize = numberOfXCells * this.CELL_SIZE;
    const adjustment = Math.max(
      this.MINIMUM_GRAPH_WIDTH - plotSize - this.getMarginLeft() - this.DEFAULT_MARGINS.right,
      0,
    );
    return this.DEFAULT_MARGINS.right + adjustment;
  }

  private getMarginBottom(numberOfXCells: number): number {
    // X-axis title can potentially wrap multiple lines when not enough horizontal space.
    const adjustment =
      numberOfXCells <= this.SMALL_NUMBER_OF_CELLS && this.xAxisTitleIsLong
        ? this.WRAPPED_TITLE_ADJUSTMENT
        : 0;

    // Long x-axis labels require a far larger margin on the bottom.
    const longestXLabel = this.data.labels.x.reduce((acc, curr) => Math.max(acc, curr.length), 0);
    const longXLabel = longestXLabel >= this.LARGE_LABEL_LENGTH;
    const xAxisLongLabelAdjustment = longXLabel ? 100 : 0;
    return this.DEFAULT_MARGINS.bottom + adjustment + xAxisLongLabelAdjustment;
  }

  private getMarginLeft(): number {
    // Y-axis label can wrap multiple lines when not enough vertical space.
    const hasFewYLabels = this.hasFewYLabels;
    const yAxisWrappedTitleAdjustment =
      hasFewYLabels && this.yAxisTitleIsLong ? this.WRAPPED_TITLE_ADJUSTMENT : 0;

    // Long y-axis labels require a far larger margin on the left.
    const longestYLabel = this.data.labels.y.reduce((acc, curr) => Math.max(acc, curr.length), 0);
    let yAxisLongLabelAdjustment = 0;
    if (longestYLabel >= 2 * this.LARGE_LABEL_LENGTH) {
      yAxisLongLabelAdjustment = 150;
    } else if (longestYLabel >= this.LARGE_LABEL_LENGTH) {
      yAxisLongLabelAdjustment = 50;
    }

    return this.DEFAULT_MARGINS.left + yAxisWrappedTitleAdjustment + yAxisLongLabelAdjustment;
  }

  private get hasFewYLabels(): boolean {
    // Length of 1 is a special case that is rendered differently.
    // Doesn't seem to wrap the y-axis title even when it would if there were 2 or more labels.
    return (
      this.data.labels?.y.length > 1 && this.data.labels?.y.length <= this.SMALL_NUMBER_OF_CELLS
    );
  }

  private get xAxisTitleIsLong(): boolean {
    return this.xAxisTitle?.length >= this.LARGE_AXIS_TITLE_LENGTH;
  }

  private get yAxisTitleIsLong(): boolean {
    return this.yAxisTitle?.length >= this.LARGE_AXIS_TITLE_LENGTH;
  }

  private get titleIsLong(): boolean {
    return this.title?.length >= this.LARGE_TITLE_LENGTH;
  }

  static getPointValue(point: number[] | PointOptionsObject): number {
    if (Array.isArray(point)) {
      return point[2];
    } else if ((point as PointOptionsObject).value !== undefined) {
      return point.value;
    }
  }

  static getPointX(point: number[] | PointOptionsObject): number {
    if (Array.isArray(point)) {
      return point[0];
    } else if ((point as PointOptionsObject).x !== undefined) {
      return point.x;
    }
  }

  static getPointY(point: number[] | PointOptionsObject): number {
    if (Array.isArray(point)) {
      return point[1];
    } else if ((point as PointOptionsObject).y !== undefined) {
      return point.y;
    }
  }
}

export interface HeatmapData {
  labels: { x: string[]; y: string[] };
  tooltipLabel: string;
  series: SeriesHeatmapOptions;
}

export interface HeatmapChartOptions {
  chartOptions: ChartOptions;
  series: SeriesHeatmapOptions[];
  xAxisOptions: XAxisOptions;
}

export const isHeatmapData = (data: HeatmapData | GridData): data is HeatmapData => {
  return (data as HeatmapData).series !== undefined;
};
